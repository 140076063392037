import { Row, Col, Form, Button, Image, Typography } from "antd"
import { MyInput, MySelect } from "../../Components"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import "./index.css"
import { apiCalls } from "../../Shared"
const AddPortal= ()=>{
    //C4-E2-1F-0B-2C-2C
    const [form] = Form.useForm()
    let { macAddress } = useParams(); 
    const [selectedPortalType, setSelectedPortalType]= useState(1)
    const [loading, setLoading]= useState(false)
    const portalType = [
        {
            name: 'M3U',
            id:1
        },
        {
            name: 'Stalker',
            id:2
        },
        {
            name: 'Xtreme Code API',
            id:3
        },
    ]
    useEffect(()=>{
        if(macAddress)
            form.setFieldValue("macAddress", macAddress)
    }, [macAddress])

    const addNewPortal= async ()=>{

        setLoading(true)
        const data= form.getFieldsValue()
        let result= await apiCalls?.addPortal?.addNewPortal({
            ...data,
            methodType: data?.portalType?.toString()
        })
        if(result){
            form.resetFields()
            form.setFieldValue("macAddress", macAddress)
        }
        setLoading(false)
    }
    return (
        <div className="cover py-5">
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                onFinish={addNewPortal}
                className="add-portal-form"
            
            >
            <Row gutter={[16, 0]} align="middle">
                    <Col span={24} className="center mb-4">
                        <Image 
                            src="/assets/images/logo.jpg" 
                            alt="my-enjoy-tv" 
                            width='250px' 
                            style={{borderRadius: '3px'}}
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput 
                            disabled
                            size='large'
                            label='MAC Address'
                            name='macAddress'
                            placeholder='e.g 00:B0:D0:63:C2:46'
                            required
                            message='please enter mac address'
                            onChange={(e) => {
                                const inputValue = e.target.value;
                                const cleanedValue = inputValue.replace(/\W/g, '');
                                const formattedMacAddress = cleanedValue.match(/.{1,2}/g)?.join(':').slice(0, 17) || '';
                                form.setFieldsValue({ ...form?.getFieldsValue(), macAddress: formattedMacAddress });
                            }} 
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MySelect 
                            label= {'portal type'}
                            name= "portalType"
                            required
                            message='Please choose portal type'
                            placeholder='Select portal type'
                            options={portalType}
                            onChange= {(value)=> setSelectedPortalType(value)}
                            size='large'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label= 'Title'
                            name= 'name'
                            placeholder='e.g my m3u'
                            required
                            message='Please enter title'
                            size='large'
                        />
                    </Col>
                    <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                        <MyInput
                            label= "Url"
                            name= "url"
                            placeholder='e.g http://syhvv.pac:8080'
                            required
                            message='Please enter url'
                            size='large'
                        />
                    </Col>
                    {
                        selectedPortalType === 3?
                        <>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Username'
                                    name= "userName"
                                    placeholder='e.g myapi.er'
                                    required
                                    message='Please enter username'
                                    size='large'
                                />
                            </Col>
                            <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                                <MyInput
                                    label='Password'
                                    name= "password"
                                    placeholder='e.g Iptv@123'
                                    required
                                    message='Please enter password'
                                    size='large'
                                />
                            </Col>
                        </>
                        :
                        <></>
                    }
                    <Col span={24} className="center">
                        <div style={{width: '220px'}}>
                            <Button 
                                size="large"
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                block
                            >
                                Add Portal
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}
export {AddPortal}