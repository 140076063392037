import { message } from "antd"

const addNewPortal= (payload)=>{
    
    var myHeaders = new Headers()
    myHeaders.append("Content-Type", "application/json")       
    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body:JSON.stringify(payload)
    }
    return (
        fetch("https://iptv-backend.healthflowpro.com/api" + `/addPortal`, requestOptions)
        .then(response => response.json())
        .then(result => { 
                      
            if (result?.success){
                message.success(result?.message)
                return 1
            }
            else
                throw result?.message
        })
        .catch(error => { 
            message.error(error)
            return 0 
        })
    )
}
export const addPortal={addNewPortal}