import * as React from "react"
import {
  createBrowserRouter,
  RouterProvider,
  Link
} from "react-router-dom"
import { AddPortal } from "../Pages"

const router = createBrowserRouter([
  {
    path: "/addPortal/:macAddress",
    element: (<AddPortal/>)
  }
])
const Routing= ()=>{
    return (
        <RouterProvider router={router} />
    )
}
export default Routing
