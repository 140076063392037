import Routing from './Routing'
import { ConfigProvider } from 'antd'

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#F83605',
          borderRadius: 3
        }
      }}
    >
      <Routing/>
    </ConfigProvider>
  )
}

export default App;
